<template>
    <div class="subscribe-block">
        <div v-if="!currencyChosen || !periodChosen" class="empty">
            <div class="empty-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="161" height="160" viewBox="0 0 161 160" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M38.5898 132.71C40.7831 134.903 43.4198 136 46.4998 136H113.7C116.78 136 119.416 134.903 121.61 132.71C123.803 130.517 124.9 127.88 124.9 124.8V57.6L91.2998 24H46.4998C43.4198 24 40.7831 25.0967 38.5898 27.29C36.3965 29.4833 35.2998 32.12 35.2998 35.2V124.8C35.2998 127.88 36.3965 130.517 38.5898 132.71ZM85.6998 35.2V63.2H113.7V124.8H46.4998V35.2H85.6998Z"
                        fill="#E8EAED" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M38.5898 132.71C40.7831 134.903 43.4198 136 46.4998 136H113.7C116.78 136 119.416 134.903 121.61 132.71C123.803 130.517 124.9 127.88 124.9 124.8V57.6L91.2998 24H46.4998C43.4198 24 40.7831 25.0967 38.5898 27.29C36.3965 29.4833 35.2998 32.12 35.2998 35.2V124.8C35.2998 127.88 36.3965 130.517 38.5898 132.71ZM85.6998 35.2V63.2H113.7V124.8H46.4998V35.2H85.6998Z"
                        fill="url(#paint0_linear_1413_23101)" />
                    <g filter="url(#filter0_b_1413_23101)">
                        <path
                            d="M147.883 98.3997H89.1164C87.2294 98.3997 85.6997 100.191 85.6997 102.4V126.4C85.6997 128.609 87.2294 130.4 89.1164 130.4H147.883C149.77 130.4 151.3 128.609 151.3 126.4V102.4C151.3 100.191 149.77 98.3997 147.883 98.3997Z"
                            fill="#E3E2E9" fill-opacity="0.08" />
                    </g>
                    <path
                        d="M98.8328 109.733C97.9124 109.733 97.1662 108.987 97.1662 108.067C97.1662 107.146 97.9124 106.4 98.8328 106.4H142.833C143.753 106.4 144.5 107.146 144.5 108.067C144.5 108.987 143.753 109.733 142.833 109.733H98.8328ZM98.8328 116.4C97.9124 116.4 97.1662 115.654 97.1662 114.733C97.1662 113.813 97.9124 113.067 98.8328 113.067H142.833C143.753 113.067 144.5 113.813 144.5 114.733C144.5 115.654 143.753 116.4 142.833 116.4H98.8328ZM98.8328 123.067C97.9124 123.067 97.1662 122.32 97.1662 121.4C97.1662 120.479 97.9124 119.733 98.8328 119.733H142.833C143.753 119.733 144.5 120.479 144.5 121.4C144.5 122.32 143.753 123.067 142.833 123.067H98.8328ZM92.1662 109.733C91.694 109.733 91.2981 109.574 90.9787 109.254C90.6592 108.935 90.4995 108.539 90.4995 108.067C90.4995 107.594 90.6592 107.199 90.9787 106.879C91.2981 106.56 91.694 106.4 92.1662 106.4C92.6384 106.4 93.0342 106.56 93.3537 106.879C93.6731 107.199 93.8328 107.594 93.8328 108.067C93.8328 108.539 93.6731 108.935 93.3537 109.254C93.0342 109.574 92.6384 109.733 92.1662 109.733Z"
                        fill="#E3E2E9" fill-opacity="0.16" />
                    <g filter="url(#filter1_b_1413_23101)">
                        <path
                            d="M60.1664 42.3997H20.033C18.7444 42.3997 17.6997 43.4443 17.6997 44.733V47.9997V81.5997V84.8663C17.6997 86.155 18.7444 87.1997 20.033 87.1997H60.1664C61.455 87.1997 62.4997 86.155 62.4997 84.8663V81.5997V47.9997V44.733C62.4997 43.4443 61.455 42.3997 60.1664 42.3997Z"
                            fill="#E3E2E9" fill-opacity="0.12" />
                    </g>
                    <path
                        d="M34.5245 64.0502C33.4128 64.0502 32.4641 63.6573 31.6783 62.8715C30.8924 62.0857 30.4995 61.1369 30.4995 60.0252C30.4995 58.9136 30.8924 57.9648 31.6783 57.179C32.4641 56.3932 33.4128 56.0002 34.5245 56.0002C35.6362 56.0002 36.5849 56.3932 37.3708 57.179C38.1566 57.9648 38.5495 58.9136 38.5495 60.0252C38.5495 61.1369 38.1566 62.0857 37.3708 62.8715C36.5849 63.6573 35.6362 64.0502 34.5245 64.0502ZM34.5245 61.7502C35.0037 61.7502 35.411 61.5825 35.7464 61.2471C36.0818 60.9117 36.2495 60.5044 36.2495 60.0252C36.2495 59.5461 36.0818 59.1388 35.7464 58.8034C35.411 58.468 35.0037 58.3002 34.5245 58.3002C34.0453 58.3002 33.6381 58.468 33.3026 58.8034C32.9672 59.1388 32.7995 59.5461 32.7995 60.0252C32.7995 60.5044 32.9672 60.9117 33.3026 61.2471C33.6381 61.5825 34.0453 61.7502 34.5245 61.7502ZM44.8745 74.4002C43.7628 74.4002 42.8141 74.0073 42.0283 73.2215C41.2424 72.4357 40.8495 71.4869 40.8495 70.3752C40.8495 69.2636 41.2424 68.3148 42.0283 67.529C42.8141 66.7432 43.7628 66.3502 44.8745 66.3502C45.9862 66.3502 46.9349 66.7432 47.7208 67.529C48.5066 68.3148 48.8995 69.2636 48.8995 70.3752C48.8995 71.4869 48.5066 72.4357 47.7208 73.2215C46.9349 74.0073 45.9862 74.4002 44.8745 74.4002ZM44.8745 72.1002C45.3537 72.1002 45.761 71.9325 46.0964 71.5971C46.4318 71.2617 46.5995 70.8544 46.5995 70.3752C46.5995 69.8961 46.4318 69.4888 46.0964 69.1534C45.761 68.818 45.3537 68.6502 44.8745 68.6502C44.3953 68.6502 43.9881 68.818 43.6526 69.1534C43.3172 69.4888 43.1495 69.8961 43.1495 70.3752C43.1495 70.8544 43.3172 71.2617 43.6526 71.5971C43.9881 71.9325 44.3953 72.1002 44.8745 72.1002ZM32.9145 73.5952C32.4699 74.0398 31.7491 74.0398 31.3045 73.5952C30.8599 73.1507 30.8599 72.4298 31.3045 71.9852L46.4845 56.8052C46.9291 56.3607 47.6499 56.3607 48.0945 56.8052C48.5391 57.2498 48.5391 57.9707 48.0945 58.4152L32.9145 73.5952Z"
                        fill="#E3E2E9" fill-opacity="0.16" />
                    <defs>
                        <filter id="filter0_b_1413_23101" x="69.6997" y="82.3997" width="97.6001" height="64"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1413_23101" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1413_23101"
                                result="shape" />
                        </filter>
                        <filter id="filter1_b_1413_23101" x="1.69971" y="26.3997" width="76.7998" height="76.8"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1413_23101" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1413_23101"
                                result="shape" />
                        </filter>
                        <linearGradient id="paint0_linear_1413_23101" x1="21.0628" y1="74.4" x2="110.102" y2="111.973"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4A7EFF" />
                            <stop offset="0.486792" stop-color="#61BEDA" />
                            <stop offset="1" stop-color="#404C73" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div class="empty-title title__medium">Choose Your Subscription</div>
            <div class="empty-desc body__large">Select a currency and a subscription period to continue.</div>
        </div>
        <div v-if="currencyChosen && periodChosen" class="subscribe">
            <div class="subscribe-card">
                <div class="subscribe-header">
                    <div class="currency">
                        <div class="image"><img :src="staticBase + `currencies/${currencyChosen}.png`"></div>
                        <div class="name body__large"> {{ currencyChosen }}</div>
                    </div>
                </div>
                <div class="subscribe-body">
                    <div class="subscribe-body-top">
                        <div class="subscribe-period">
                            <h2 class="label__mediumprominent">{{ tokens.fullScreenSavings.periodChosen }}</h2>
                            <span class="body__large">{{ tokens.fullScreenSavings[periodChosen] }}</span>
                        </div>
                        <div class="subscribe-rate">
                            <h2 class="label__mediumprominent">{{ "Rate" }}</h2>
                            <span class="body__large">{{ stakingCurrencies[currencyChosen][periodChosen].toFixed(1)
                                }}%</span>
                        </div>
                        <div class="subscribe-release">
                            <h2 class="label__mediumprominent">{{ tokens.fullScreenSavings.releaseTime }}</h2>
                            <span class="body__large">
                                {{ new Date(time + periodChosen.split('_')[1] * 86400000).toLocaleString() }}
                            </span>
                        </div>
                    </div>
                    <div class="subscribe-body-bottom">
                        <div class="subscribe-current">
                            <h2 class="label__mediumprominent">{{ tokens.fullScreenSavings.currentTime }}</h2>
                            <span class="label__mediumprominent">{{ new Date(time).toLocaleString() }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="subscribe-account-select">
                <label class="label__largeprominent">Account to transfer funds from</label>
                <BaseAccountSelect :selectedAccount="selectedAccountComputed" @update="chooseAccount($event)" />
            </div>
            <div class="subscribe-account-amount">
                <BaseNumberInput
                    v-model="amountChosen"
                    :type="'number'"
                    :title='`Amount (in ${currencyChosen})`'>
                        <template #icon>
                            <!-- need to refactored -->
                            <span class="body__large" style="font-size: 11px;">{{ currencyChosen }}</span>
                        </template>
                </BaseNumberInput>
            </div>
        </div>
        <div class="subscribe-account-button" v-if="currencyChosen && periodChosen">
            <BaseButton
                :buttonText='`Open Savings Account and Invest ${amountChosen || 0} ${currencyChosen}`'
                :buttonStyle="'filled-primary'"
                @click.native="openSavingsAccount" />
        </div>
        <SavingSuccess
            v-if="savingSuccessModal.state"
            :account="savingSuccessModal.data"
            :time="time"
            @openSavingsAccountsTab="openSavingsAccountsTab"
            @close="closeSavingSuccessModal" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STAKING_MAKE } from '@/store/actions.type'
import { NOTIFICATION_ERROR } from "@/store/mutations.type"
import BaseNumberInput from "@/new-design/shared/ui/Inputs/BaseNumberInput"
import BaseAccountSelect from "@/new-design/shared/ui/selects/BaseAccountSelect"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton"
import SavingSuccess from '@/new-design/not-sorted/new/SavingsPage/SavingSuccess.vue'

export default {
    name: 'SubscribeBlock',
    components: {
        BaseAccountSelect,
        BaseNumberInput,
        BaseButton,
        SavingSuccess,
    },
    props: {
        periodChosen: {
            type: String
        },
        currencyChosen: {
            type: String
        },
        time: {
            type: [Number, String]
        },
        savingModalState: {
            type: Boolean
        }
    },
    data() {
        return {
            selectedAccount: null,
            amountChosen: 0,
            savingSuccessModal: {
                state: false,
                data: {}
            },
        }
    },
    computed: {
        ...mapGetters(['tokens', 'activeAccount', 'stakingCurrencies', 'staticBase']),
        selectedAccountComputed() {
            return this.selectedAccount || this.activeAccount
        },
    },
    methods: {
        openSavingsAccount() {
            // kill if amount is below minimum
            if (this.amountChosen < this.minDeposit) {
                this.$store.commit(NOTIFICATION_ERROR, this.tokens.errors.LOCAL_MIN_STAKING + this.minDeposit)
                return
            }

            this.$store.dispatch(STAKING_MAKE, {
                currency: this.currencyChosen,
                amount: this.amountChosen,
                period: this.periodChosen.split('_')[1],
                account_from: this.selectedAccountComputed.id
            }).then(account => {
                this.savingSuccessModal.state = true
                this.savingSuccessModal.data = account
                this.$emit('resetVars')
            })
        },
        chooseAccount(e) {
            this.selectedAccount = e;
        },
        openSavingsAccountsTab() {
            this.$emit('openSavingsAccountsTab');
            this.closeSavingSuccessModal();
        },
        closeSavingSuccessModal() {
            this.savingSuccessModal.state = false
        }
    },
    watch: {
        currencyChosen(val) {
            // set minimum deposit as default when user picks currency:
            for (let n in this.stakingCurrencies) {
                if (n === val) {
                    this.minDeposit = this.stakingCurrencies[n].min_deposit // minDeposit is also used in checking when trying to open a saving
                    this.amountChosen = this.minDeposit
                }
            }
        },
        savingModalState(val){
            if(!val){
                this.closeSavingSuccessModal()
            }
        }
    }
}
</script>

<style scoped>
.subscribe-block {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    position: relative;
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    text-align: center;
    padding-inline: 20px;
}

.empty-title {
    color: var(--System-OnSurfaceVariant)
}

.empty-desc {
    max-width: 320px;
    color: var(--System-Outline)
}

.subscribe {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 16px;
}

.subscribe-card {
    border: 1px solid var(--System-OutlineVariant);
    border-radius: 4px;
    overflow: hidden;
}

.subscribe-header {
    background: var(--System-Surface3);
    padding: 14px 16px;
}

.currency {
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 10px;
}

.image img {
    height: 24px;
}

.name {
    color: var(--System-OnSurface);
}

.subscribe-body {
    padding: 16px;
}

.subscribe-body-top {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 16px;
}

.subscribe-body-top span {
    color: var(--System-OnSurface);

}

.subscribe-period,
.subscribe-rate,
.subscribe-release {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.subscribe-body-bottom {
    border-top: 1px solid var(--System-OutlineVariant);
    padding-top: 16px;
}

.subscribe-current {
    display: flex;
    align-items: center;
    gap: 5px;
}

.subscribe-account-select {
    margin: 32px 0 24px;
}

.subscribe-account-button {
    margin-top: auto;
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        width: 100%;
    }
}
</style>
