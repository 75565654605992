<template>
    <div class="bank-transfer">
        <div v-if="!hasPendingBankDeposit" class="bank">
            <h5 class="title body__medium">Our banking details</h5>
            <div class="bank-details">
                <BaseModalChipsList class="chips">
                    <template #content>
                        <BaseSmallChip
                            class="config-chips"
                            v-for="config in bankConfig"
                            :key="config.id"
                            :text="`${config.alias} - ${config.currency}`"
                            :class="{ active: bank && bank.alias === config.alias }"
                            @click.native="changeBank(config)"
                        />
                    </template>
                </BaseModalChipsList>
                <div class="name">
                    <p class="body__medium desc">{{ bank.alias }} - {{ currency }}</p>
                    <BaseButtonSmall
                        :button-style="'outlined-primary'"
                        :button-text="'copy'"
                        :icon="'content_copy'"
                        @click.native="copy(bank)"
                    />
                </div>
                <div class="bank-info">
                    <div class="bank-item" v-for="(variable, i) in bank" :key="i" v-show="variable && i !== 'alias' && i !== 'currency'">
                        <p class="key body__small">{{ tokens.depositModal[i] }}</p>
                        <span class="value body__small" v-if="i"> : {{ variable }}</span>
                    </div>
                </div>
            </div>
            <h5 class="title body__medium">Order details</h5>
            <div class="order-info">
                <BaseInput class="amount" v-model="amount" :type="'number'" :title="'Amount'" :placeholder="'0.00'">
                    <template #icon>
                        <p class="body__large">{{ currencySign(currency) }}</p>
                    </template>
                </BaseInput>
            </div>
            <div class="action">
                <p class="info body__extrasmall">*The deposit via {{ method }} is processed within 5-10 minutes</p>
                <BaseButton
                    :button-text="'Generate Invoice'"
                    :disabled="!amount"
                    :button-style="'filled-primary'"
                    @click.native="makeDeposit"
                />
            </div>
        </div>
        <div v-else>
            <h5 class="title body__medium">You have a pending bank transfer deposit. Please do the following:</h5>
            <ul class="bank-steps">
                <li>
                    <h2 class="title"><span>1</span> Download the invoice</h2>
                    <div class="center">
                        <button class="fs-normal" @click="downloadPdf(bankDeposit.id)">Download PDF</button>
                    </div>
                </li>
                <li>
                    <h2 class="title"><span>2</span> Pay the invoice in your bank</h2>
                </li>
                <li v-show="!bankDeposit.misc">
                    <h2 class="title"><span>3</span> Upload the confirmation receipt</h2>
                    <div class="center">
                        <label for="file" class="attach">Select File</label>
                        <input type="file" id="file" @change="uploadConfirmation($event)">
                    </div>
                    <div class="center">or drag and drop file onto this page</div>
                </li>
                <li v-show="bankDeposit.misc">
                    <h2 class="title"><span>3</span> The receipt has been uploaded</h2>
                </li>
                <li>
                    <h2 class="title"><span>4</span> Wait for deposit confirmation</h2>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import toggler from "@/new-design/shared/mixins/toggler"
import {STATIC_BASE} from "@/common/config"
import {currencySign} from "@/common/helpers"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"
import BaseInput from "@/new-design/shared/ui/Inputs/BaseInput.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import {mapGetters} from "vuex"
import BaseModalChipsList from "@/new-design/shared/ui/chips/BaseModalChipsList.vue"
import BaseSmallChip from "@/new-design/shared/ui/chips/BaseSmallChip.vue"
import {BANK_DEPOSIT, NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {DOWNLOAD_INVOICE, UPLOAD_CONFIRMATION} from "@/store/actions.type";

export default {
    name: "BankTransferMethod",
    components: {BaseSmallChip, BaseModalChipsList, BaseButton, BaseInput, BaseButtonSmall},
    mixins: [toggler],
    mounted() {
        this.init()
    },
    props: {
        currencies: {
            type: Array,
            default: () => []
        },
        method: {
            type: String
        }
    },
    data() {
        return {
            amount: null,
            staticBase: STATIC_BASE,
            selectedCurrency: '',
            selectedBank: null,
            hasPendingBankDeposit: false,
            pendingBankDepositId: null,
        }
    },
    computed: {
        currency: {
            get() {
                return this.selectedCurrency || (this.currencies.length ? this.currencies[0] : '')
            },
            set(value) {
                this.selectedCurrency = value
            }
        },
        bank: {
            get() {
                let bank = this.selectedBank || (this.bankConfig.length ? this.bankConfig[0] : null)

                if (!bank) {
                    return
                }

                delete bank.id
                delete bank.enable
                delete bank.ib
                delete bank.countries_excluded
                delete bank.countries_included
                return bank
            },
            set(value) {
                this.selectedBank = value
                this.currency = value.currency
            }
        },
        ...mapGetters(['bankConfig', 'tokens', 'deposits','bankDeposit'])
    },
    methods: {
        currencySign,
        init() {
            this.amount = null
        },
        changeBank(bank) {
            this.bank = bank
        },
        makeDeposit() {
            this.$emit('update', {
                info: {
                    amount: this.amount,
                    currency: this.currency
                }
            })
        },
        copy(data) {
            let line, copiedText = ''

            for (const item in data) {
                if (this.tokens.depositModal[item]) {
                    line = `${this.tokens.depositModal[item]} : ${data[item]}`
                    copiedText += `${line} \n`
                }
            }

            navigator.clipboard.writeText(copiedText)
                .then(() => {
                    this.$store.commit(NOTIFICATION, `Our banking details copied to clipboard`)
                })
        },
        downloadPdf(id) {
            this.$store.dispatch(DOWNLOAD_INVOICE, {
                id: id,
                currency: this.currency,
                depositId: this.pendingBankDepositId,
                tokens: {
                    BT_BENEFICIARY_TITLE: this.tokens.depositModal.beneficiary,
                    BT_ACCOUNT_TITLE: this.tokens.depositModal.account,
                    BT_BANK_NAME_TITLE: this.tokens.depositModal.bank_name,
                    BT_SWIFT_TITLE: this.tokens.depositModal.swift,
                    BT_IBAN_TITLE: this.tokens.depositModal.iban,
                    BT_ADDRESS_TITLE: this.tokens.depositModal.beneficiary_address,
                    BT_INN: this.tokens.depositModal.ru_inn,
                    BT_KPP: this.tokens.depositModal.ru_kpp,
                    BT_CORRESPONDING: this.tokens.depositModal.corresponding_account,
                    token_description: this.tokens.depositModal.TOKEN_DESCRIPTION,
                    token_amount: this.tokens.depositModal.TOKEN_AMOUNT,
                    token_subtotal: this.tokens.depositModal.TOKEN_SUBTOTAL,
                    token_total: this.tokens.depositModal.TOKEN_TOTAL,
                    token_payment_details: this.tokens.depositModal.TOKEN_PAYMENT_DETAILS
                }
            })
        },
        uploadConfirmation(e) {
            if (!e.target.files) {
                return
            }

            if (['image/jpeg', 'image/png', 'application/pdf'].indexOf(e.target.files[0].type) > -1) {
                let data = new FormData()
                data.append('file', e.target.files[0])
                this.$store.dispatch(UPLOAD_CONFIRMATION, data)
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
            e.target.value = ''
        },
    },
    watch: {
        'method': {
            handler() {
                this.init()
            }
        },
        deposits: {
            deep: true,
            handler(newVal) {
                this.hasPendingBankDeposit = false // state.deposits has changed, so we default this var and then check the array
                let bankDepositId = 0
                newVal.forEach(deposit => {
                    if (deposit.source === 7 && (deposit.status === 0 || deposit.status === 3)) {
                        bankDepositId = deposit.id
                        this.hasPendingBankDeposit = true
                        this.pendingBankDepositId = deposit.id
                        this.$store.commit(BANK_DEPOSIT, this.deposits.filter(d => d.id === bankDepositId)[0])
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.title {
    color: var(--System-OnSurface);
    padding: 16px 0;
}

.bank-details {
    padding: 16px;
    border-radius: var(--Corner-Extra-small);
    background: var(--System-Surface1);
}

.name {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 16px 0 20px;
    border-bottom: 1px solid var(--System-Surface5);
    margin-bottom: 16px;
    place-items: center stretch;
}

.desc {
    color: var(--System-OnSurface);
}

.order-info {
    padding-bottom: 16px;
}

.bank-item {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 12px;
}

.bank-item:last-child {
    margin-bottom: 0;
}

.bank-item .key {
    color: var(--System-OnSurfaceVariant);
}

.bank-item .value {
    color: var(--System-OnSurface);
}

img {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.action {
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 16px;
    padding: 8px 0;
}

.config-chips {
    border: 1px solid var(--System-OutlineVariant);
}

button {
    width: fit-content;
    margin: 0 0 0 auto;
}

.bank-steps li {
    width: 400px;
    min-height: 150px;
    margin-top: 20px;
    border-radius: 5px;
    background: var(--System-OutlineVariant);
    padding: 30px;
    display: grid;
    place-items: center start;
}

.bank-steps li * {
    text-align: center;
}

.bank-steps li > *:not(:last-child) {
    margin-bottom: 20px;
}

.bank-steps li div.center {
    width: 100%;
    text-align: center;
    font-size: 1em;
}

.bank-steps h2 span {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    display: inline-grid;
    place-items: center;
    font-size: 22px;
    color: var(--System-OnSurface);
    background: var(--System-OnSurfaceVariant);
    border-radius: 50%;
}

.light-theme .bank-steps h2 span {
    background-color: #e0e3eb;
}

#file {
    display: none;
}

label.attach {
    margin-right: 10px;
    border: 1px solid var(--fs);
    background: var(--background-dark-mid);
    border-radius: 5px;
    color: var(--white-to-black);
    cursor: pointer;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
}

.over {
    position: relative;
}

.over::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    background: rgba(0, 0, 0, .5);
    border: 4px dashed white;
}

.info {
    text-align: left;
}

@media (max-width: 468px) {
    .title {
        text-align: left;
    }
}
</style>
