<template>
    <div class="menu">
        <div class="placeholder">
            <p class="active-item body__large">{{ placeholder }}</p>
            <span class="material-symbols-outlined">arrow_right</span>
        </div>
        <div class="modal-menu-wrapper" v-if="state">
            <div class="modal-title">
                <div>
                    <i class="material-icons close arrow-icon">{{ 'arrow_back' }}</i>
                    <h4 class="title__large">
                        {{ !options.some(option => option.key === 'USD') ? 'Language' : 'Dashboard currency' }}
                    </h4>
                </div>
                <BaseButton
                    v-if="isMobile"
                    :buttonText="'Save'"
                    :buttonStyle="'filled-primary'"
                    @click.native="save"/>
            </div>
            <div class="modal-inner" :class="[position]">
                <ul v-if="state" class="menu-list ElevationLight__2">
                    <li v-for="(option, i) in options"
                        :key="i" class="option body__medium"
                        :class="{'active': option.key === selected.key}"
                        @click="select($event, option)">
                            <slot :option="option" name="option">{{ option.title }}</slot>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton"
import {isMobile} from "@/common/helpers"
export default {
    name: "BaseSelectModalBox",
    components: {  BaseButton },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        state: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'right'
        }
    },

    data(){
        return {
            isMobile: isMobile(),
            selected: isMobile() ? this.options.find(option => option.title === this.placeholder) : {}
        }
    },
    methods: {
        select(e,option) {
            if(!this.isMobile){
                this.$emit('update', option.key)
            }else{
                e.stopPropagation();
                this.selected = option
            }
        },
        save(){
            this.$emit('update', this.selected.key)
        }
    },
    watch: {
        state(val){
            if(val && this.isMobile){
                this.selected = this.options.find(option => option.title === this.placeholder)
            }
        }
    }
}
</script>

<style scoped>
.placeholder {
    display: flex;
    align-items: center;
    gap: 4px;
}

.active-item {
    color: var(--System-OnSurface);
}

.modal-inner.right .menu-list {
    bottom: 55px;
    right: -24px;
}

.modal-inner.left .menu-list {
    top: 0;
    left: -200px;
}

.menu-list {
    position: absolute;
    background: var(--System-Surface3);
    padding: 8px 0;
    max-height: 375px;
    width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: var(--Corner-Extra-small);
}

li {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 8px;
    background: transparent;
    color: var(--System-OnSurface);
}

@media(hover: hover){

    li:hover {
        background: var(--Statelayers-primary-opacity16);
    }
}

.modal-title {
    display: none;
}

.modal-title i {
    margin-bottom: 8px;
}

@media(max-width: 767px) {
    .modal-menu-wrapper {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 35;
        background: var(--System-Surface);
    }

    .modal-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        padding: 12px 16px 24px;
        color: var(--System-OnSurface);
        border-bottom: 1px solid var(--System-OutlineVariant)
    }

    .modal-inner {
        position: relative;
        width: 100%;
        height: calc(100% - 96px);
    }

    .modal-inner.right .menu-list,
    .modal-inner.left .menu-list {
        top: 0;
        left: 0;
        right: 0;
    }

    .menu-list {
        inset: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        background: var(--System-Surface);
    }

    .menu-list li {
        font-size: 16px;
        gap: 16px;
        padding: 16px;
    }
    .menu-list li.active {
        background: var(--System-Surface3)
    }
}
</style>
